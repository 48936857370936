import React from "react"
import styled from "styled-components"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const SignUpForm = () => {
  const [state, setState] = React.useState({})
  const [disabled, setDisabled] = React.useState(true)

  const handleChange = (e) => {
    if (e.target.name == "email" && e.target.value != "") {
      setDisabled(false)
    } else {
      setDisabled(true)
    }

    setState({ ...state, [e.target.name]: e.target.value})
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
    .then(() => window.location.href = "https://msp.myservicepak.com/sign_up")
      .catch((error) => alert(error))
  };

  return (
    <SiteSignUpForm id="form">
      <div className="sign-up__form--wrapper">
        <div className="signup__form--header">
          <h2>30 Day Free Trial</h2>
          <h3>Sign Up Today!</h3>
        </div>
        <form className="signup__form--contents"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="myservicepak-signup" method="POST"
          onSubmit={event => handleSubmit(event)}>
          <h4>Easy. Inexpensive. No Commitment.</h4>
          <div className="signup__form--form-group">
            <label htmlFor="name">Name
              <input type="text" id="name" name="name" onChange={handleChange} placeholder="Jane Smith"></input>
            </label>
          </div>
          <div className="signup__form--form-group">
            <label htmlFor="email">Email
              <input type="email" id="email" name="email" onChange={handleChange}placeholder="jane@myservicepak.com"></input>
            </label>
          </div>
          <button type="submit" className="btn" disabled={disabled}>
            Get Started
          </button>
        </form>
      </div>
    </SiteSignUpForm>
  )
}

export default SignUpForm

const SiteSignUpForm = styled.div`
  text-transform: uppercase;
  max-width: 500px;
  margin: 1rem auto;
  
  h2, h3, h4 {
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    color: ${props => props.theme.color.red__400};
  }

  h3 { margin-bottom: 1rem; }

  h4 { font-size: 1.5rem; }
  
  form {
    background: ${props => props.theme.color.blue__400};
    border-radius: 1rem;
    color: ${props => props.theme.color.white};
    padding: 2rem;
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      flex-direction: column;
      font-size: 1.4rem;
      margin-bottom: 0.2rem;
    }

    .signup__form--form-group {
      margin-bottom: 1.5rem;
    }

    button {
      align-self: flex-end;
      background: ${props => props.theme.color.red__400};
      border: none;
      border-radius: 4px;
      color: ${props => props.theme.color.white};
      font-weight: ${props => props.theme.font.weight.bold};
      padding: 0.5rem 1.5rem;
      width: fit-content;
      text-transform: uppercase;
    }
  }
`;
