import React from "react"
import Layout from "../components/layout"
import SignUpForm from "../components/signUpForm"
import IconSection from "../components/iconSection"
import TestimonialSection from "../components/testimonialSection"
import VideoPanel from "../components/videoPanel"
import styled from "styled-components"

const TrialLanding = () => {

  return (
    <Layout>
      <SiteTrialLanding>
        <div className="trial-landing__section--top">
          <span className="trail-landing__section--mobile-order">
            <IconSection />
          </span>
          <SignUpForm />
        </div>
        <TestimonialSection />
      </SiteTrialLanding>
      <VideoPanel />
      <SmBanner>
        <div className="sm-banner">
          <div className="sm-banner__header">
            <h3>30 Day Free Trial</h3>
            <h4>Easy. Inexpensive. No Commitment.</h4>
          </div>
          <a href="#form" className="btn">Get Started Today</a>
        </div>
      </SmBanner>
    </Layout>
  )
}

export default TrialLanding

const SiteTrialLanding = styled.div`
  max-width: 1315px;
  margin: auto;

  .trial-landing__section--top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon__section-wrapper {
      background: white;
      max-width: 800px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.sectionWidth}) {
    padding: 1rem;
    .trial-landing__section--top { 
      flex-direction: column;
      .trail-landing__section--mobile-order {
        order: 2;
      }
    }
  }
  `
const SmBanner = styled.div`
  background: ${props => props.theme.color.blue__400};
  margin: 2rem auto;
  padding: 0.5rem 2rem;
  width: fit-content;

  .sm-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    .sm-banner__header {
      color: ${props => props.theme.color.white};
      display: flex;
      margin-right: 4rem;
      flex-direction: column;
      text-transform: uppercase;
  
      h3 { color: ${props => props.theme.color.red__400}; }
      h4 { margin-bottom: 0; }
    }
  
    a {
      background: ${props => props.theme.color.red__400};
      border-radius: 4px;
      color: ${props => props.theme.color.white};
      font-weight: ${props => props.theme.font.weight.bold};
      height: fit-content;
      padding: 0.3rem 1rem;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
  @media (max-width: ${props => props.theme.breakpoint.sectionWidth}) {
    .sm-banner {
      flex-direction: column;
      justify-content: center;
      
      .sm-banner__header {
        margin-right: 0;
        h3, h4 {
          margin-bottom: 0.5rem;
        }
      }
    }
  }

`
